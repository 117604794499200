<template>
  <v-dialog v-model="show_modal_var" persistent max-width="1200">
    <v-card>
      <!-- boton en esquina de cerrar -->
      <div class="headerIcon">
        <v-icon class="secondary--text" @click="show_modal_var = false">
          mdi-close
        </v-icon>
      </div>

      <!-- form -->
      <v-card-text>
        <v-row class="mx-sm-6">
          <v-col cols="12">
            <span
              class="text-h6 secondary--text"
              v-text="'Creación de solicitud de compra'"
            />
          </v-col>
          <!-- campo unidad -->
          <v-col cols="12" sm="8" class="d-flex flex-column flex-sm-row">
            <span v-if="status_unidad" class="txt-unidad">
              Unidad {{ unidad_select_var?.nombre }}
            </span>
            <v-autocomplete
              v-else
              :error-messages="unidadErrorCmp"
              :items="ctl_unidades_var"
              @blur="$v.unidad_select_var.$touch()"
              clearable
              item-text="nombre"
              item-value="id"
              label="Unidad *"
              outlined
              v-model="unidad_select_var"
              @change="FetchCtlCifradosFtn()"
              return-object
            />

            <!-- boton que selecciona la unidad -->
            <v-btn
              v-if="!status_unidad"
              :disabled="!enabled_select"
              class="button-extra text-capitalize mt-4 mt-sm-3 ml-sm-8"
              color="secondary"
              @click="status_unidad = true"
            >
              Seleccionar
            </v-btn>
          </v-col>

          <v-col cols="12">
            <span class="text-h6 secondary--text" v-text="'Cifrados'" />
          </v-col>

          <!-- campo cifrado presupuestario -->
          <v-col cols="12" sm="6">
            <v-autocomplete
              :disabled="!status_unidad"
              :error-messages="cifradoErrorCmp"
              :items="ctl_cifrados_var"
              @blur="$v.cifrado_select_var.$touch()"
              clearable
              item-text="cifrado"
              item-value="id"
              label="Cifrado presupuestario *"
              outlined
              v-model="cifrado_select_var"
              return-object
            />
          </v-col>

          <!-- campo monto a solicitar -->
          <v-col cols="12" sm="6" class="d-flex flex-column flex-sm-row">
            <money-text-field-component
              :disabled="!status_unidad"
              ref="REF_MONEY_CONST"
              :error-messages="montoSolicitadoErrorCmp"
              @blur="$v.monto_solicitado_var.$touch()"
              clearable
              label="Monto a solicitar *"
              v-model="monto_solicitado_var"
            />

            <!-- boton que agrega el cifrado y el monto a la tabla -->
            <v-btn
              :disabled="!status_unidad"
              class="button-extra text-capitalize mt-4 mt-sm-3 ml-sm-8"
              color="secondary"
              outlined
              @click="AddCifradoFtn"
            >
              Agregar
            </v-btn>
          </v-col>

          <!-- table -->
          <v-col cols="12">
            <data-table-component
              :actions="ACTIONS_CONST"
              :headers="HEADERS_CONST"
              :items="list_cifrados_var"
              class="mb-1 px-1"
              mobile_breakpoint="1000"
              :tiene_paginacion="false"
              :inhabilitar_paginacion="true"
              :show_actions="true"
              @deleteElementEvent="DeleteCifradoFtn"
            >
              <!-- slot de monto -->
              <template v-slot:[`item.monto`]="{ item }">
                {{
                  Intl.NumberFormat("en-US", {
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item.monto)
                }}
              </template>
            </data-table-component>
          </v-col>
        </v-row>

        <!-- actions -->
        <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
          <!-- boton que cierra el modal -->
          <v-btn
            class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            outlined
            @click="show_modal_var = false"
          >
            Cancelar
          </v-btn>

          <!-- boton que crea la solicitud -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="CreateSolicitudFtn"
          >
            Crear solicitud
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
    
<script>
// importacion de componentes globales
import MoneyTextFieldComponent from "../../../components/MoneyTextFieldComponent.vue";
import DataTableComponent from "../../../components/DataTableComponent.vue";

// importacion de librerias
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  name: "ModalAddSoliComponent",

  components: {
    MoneyTextFieldComponent,
    DataTableComponent,
  },

  validations: {
    unidad_select_var: {
      required,
    },
    cifrado_select_var: {
      required,
    },
    monto_solicitado_var: {
      required,
    },
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "Unidad",
        value: "nom_unidad",
        align: "center",
      },
      {
        text: "Cifrado presupuestario",
        value: "nom_cifrado",
        align: "center",
      },
      {
        text: "Monto ($)",
        value: "monto",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-delete",
        },
        eventName: "deleteElementEvent",
      },
    ],

    // variables
    show_modal_var: false,
    status_unidad: false,
    enabled_select: false,
    unidad_select_var: null,
    cifrado_select_var: null,
    monto_solicitado_var: null,
    list_cifrados_var: [],
    ctl_unidades_var: [],
    ctl_cifrados_var: [],
  }),

  props: {
    proceso: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(["selectedUnidad"]),

    // mensaje de error computados de vuelidate
    unidadErrorCmp() {
      const errors = [];
      if (!this.$v.unidad_select_var.$dirty) return errors;
      !this.$v.unidad_select_var.required && errors.push("Unidad es requerido");
      return errors;
    },

    cifradoErrorCmp() {
      const errors = [];
      if (!this.$v.cifrado_select_var.$dirty) return errors;
      !this.$v.cifrado_select_var.required &&
        errors.push("Cifrado es requerido");
      return errors;
    },

    montoSolicitadoErrorCmp() {
      const errors = [];
      if (!this.$v.monto_solicitado_var.$dirty) return errors;
      !this.$v.monto_solicitado_var.required &&
        errors.push("Monto es requerido");
      return errors;
    },
  },

  methods: {
    // <-- ----------------- FUNCIONES REGULARES ---------------- -->

    /**
     * @description Funcion para limpiar los campos del formulario
     */
    CleanFormFtn() {
      this.cifrado_select_var = null;
      this.monto_solicitado_var = null;
      this.$refs.REF_MONEY_CONST.updateValue(null);
    },

    /**
     * @description Funcion para agregar un cifrado a la lista
     */
    AddCifradoFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        // verificar si el cifrado ya existe en la lista
        const item_exist = this.list_cifrados_var.find(
          (local_item) => local_item?.id_cifrado === this.cifrado_select_var?.id
        );

        if (item_exist) {
          this.temporalAlert({
            message: "El cifrado seleccionado ya existe en la lista",
            show: true,
            type: "warning",
          });
          return;
        }

        this.list_cifrados_var.push({
          id_unidad: this.unidad_select_var.id,
          nom_unidad: this.unidad_select_var.nombre,
          id_cifrado: this.cifrado_select_var.id,
          nom_cifrado: this.cifrado_select_var.cifrado_presupuestario,
          monto: this.monto_solicitado_var,
        });

        this.ctl_cifrados_var = this.ctl_cifrados_var.filter(
          (local_item) => local_item?.id !== this.cifrado_select_var?.id
        );

        this.CleanFormFtn();
      }
    },

    /**
     * @description Funcion para eliminar un cifrado de la lista
     * @param {Object} item_param
     */
    DeleteCifradoFtn(item_param) {
      this.list_cifrados_var = this.list_cifrados_var.filter(
        (local_item) => local_item?.id_cifrado !== item_param?.id_cifrado
      );
    },

    // <-- ----------------- FUNCIONES ASINCRONAS ---------------- -->

    /**
     * @description Funcion para obtener los datos de catalogo de cifrados (API)
     * @async
     */
    async FetchCtlCifradosFtn() {

      if (this.unidad_select_var) {
        this.cifrado_select_var = null;

        const { data, status } = await this.services.Cifrados.getCifrados({
          id_unidad: this.unidad_select_var.id,
          fondos: true,
        });
        if (status == 200) {
          if (data.length == 0) {
            this.temporalAlert({
              message: "No se encontraron cifrados para la unidad seleccionada",
              show: true,
              type: "warning",
            });
            this.enabled_select = false;
          } else {
            this.ctl_cifrados_var = data.map(item => ({
              ...item,
              cifrado: `${item.cifrado_presupuestario} - ${item.fondo.tipo_fondo === 1 ? 'Proyecto' : 'Votado'}`,
            }));
          }
        }
      }

    },

    /**
     * @description Funcion para obtener los datos de catalogo de unidades (API)
     * @async
     */
    async FetchCtlUnidadFtn() {

      const { data, status } =
        await this.services.UnidadesServices.getAllUnidades({
          id_institucion: this.proceso.id_institucion,
        });
      if (status == 200) {
        this.ctl_unidades_var = data;
      }

    },

    /**
     * @description Funcion para crear la solicitud de insumos (API)
     * @async
     */
    async CreateSolicitudFtn() {
      if (this.list_cifrados_var.length == 0) {
        this.temporalAlert({
          message: "Se debe de agregar por lo menos un cifrado presupuestario",
          show: true,
          type: "error",
        });
        return;
      } else {
        const fondos = this.list_cifrados_var.map((local_item) => {
          return {
            id_financiamiento_unidad: local_item.id_cifrado,
            monto: local_item.monto,
          };
        });


        const { data, status } =
          await this.services.ProcesoReserva.postSolicitudProcReserva(
            this.$route.params.id_proceso,
            {
              id_unidad: this.unidad_select_var?.id,
              fondos,
            }
          );

        if (status == 201) {
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });

          this.$router.push({
            name: "proceso-compra-reserva-editar-solicitud",
            params: {
              id_proceso: this.$route.params.id_proceso,
              id_solicitud: data.solicitud_compra.id,
            },
          });
        }

      }
    },
  },

  watch: {
    show_modal_var(value_param) {
      if (!value_param) {
        this.$v.$reset();
        this.CleanFormFtn();
        this.list_cifrados_var = [];
        this.unidad_select_var = null;
        this.status_unidad = false;
      }
    },
    unidad_select_var(value) {
      value ? (this.enabled_select = true) : (this.enabled_select = false);
    },
  },

  created() {
    this.FetchCtlUnidadFtn();
  },
};
</script>
<style scoped>
.txt-unidad {
  font-size: 1rem;
  color: #000;
}
</style>
  <!-- Componente Modal para agregar solicitudes al proceso -->