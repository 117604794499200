<template>
  <section>
    <!-- header -->
    <v-tabs
      align-with-title
      background-color="bgMinsal"
      class="v-step-0"
      mobile-breakpoint="0"
      transition
      v-model="selected_tab_var"
    >
      <div class="d-flex" v-for="tab in tabs_var" :key="tab.id">
        <v-tab class="text-no-style mx-0" v-if="tab.show">
          <v-icon>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </div>
    </v-tabs>

    <!-- body -->
    <v-tabs-items v-model="selected_tab_var" touchless>
      <div v-for="tab in tabs_var" :key="tab.id">
        <v-tab-item v-if="tab.show" class="py-12">
          <component :is="tab.component" :proceso="proceso" :key="selected_tab_var" @reload="getProceso" />
        </v-tab-item>
      </div>
    </v-tabs-items>
  </section>
</template>
    
<script>
// importaciones de componentes locales
import TabGeneralComponent from "./Tabs/TabGeneralComponent.vue";
import TabSolicitudesComponent from "./Tabs/TabSolicitudesComponent.vue";

export default {
  name: "ContainerPTabsComponent",

  data: () => ({
    // variables
    tabs_var: [],
    selected_tab_var: 0,
    proceso: null,
  }),

  methods: {
    // <-- ----------------- FUNCIONES REGULARES ---------------- -->

    /**
     *
     * @description Inicializa las tabs del componente
     */
    initTabs() {
      const EDIT_TYPE_SCP_CONST =
        this.$route.meta.type_action == "edit" ? true : false;

      // const CREATE_TYPE_SCP_CONST =
      //   this.$route.meta.type_action == "create" ? true : false;
      if(this.$route.params.id_proceso) this.getProceso();

      this.tabs_var = [
        {
          id: 0,
          name: "General",
          icon: "mdi-clipboard-text",
          component: TabGeneralComponent,
          show: true,
        },
        {
          id: 1,
          name: "Solicitudes",
          icon: "mdi-archive",
          component: TabSolicitudesComponent,
          show: EDIT_TYPE_SCP_CONST,
        },
      ];
    },

    async getProceso() {

      const response = await this.services.PacProcesos.getProceso(this.$route.params.id_proceso);
      if (response.status === 200) this.proceso = response.data;

    },
  },

  created() {
    this.initTabs();
  },
};
</script>
<!-- Componente contenedor de tabs de creacion de proceso -->