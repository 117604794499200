<template>
  <section>
    <v-row>
      <!-- header -->
      <v-col cols="12" class="py-0">
        <div
          class="mb-6 d-flex justify-center flex-column justify-sm-start flex-sm-row"
        >
          <!-- boton que redirige a la vista de agregar solicitud al proceso -->
          <v-btn
            class="button-extra text-no-style mt-4 mt-sm-0 mx-8 mx-sm-4"
            color="secondary"
            @click="$refs.REF_MODAL_CONST.show_modal_var = true"
          >
            Agregar solicitud
          </v-btn>
        </div>
      </v-col>

      <!-- table -->
      <v-col cols="12">
        <data-table-component
          class="px-1"
          :actions="ACTIONS_CONST"
          :headers="HEADERS_CONST"
          :items="list_solicitudes_st"
          mobile_breakpoint="1200"
          table_title="Listado de solicitudes"
          :show_actions="true"
          :total_registros="pag_solicitudes_st.total_rows"
          @paginar="PaginateFtn"
          v-models:pagina="pag_solicitudes_st.page"
          v-models:select="pag_solicitudes_st.per_page"
          @editElementEvent="ToViewFtn"
          @deleteElementEvent="OpenModalDeleteFtn"
        >
          <!-- slot de fecha inicio -->
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") }}
          </template>
        </data-table-component>
      </v-col>
    </v-row>

    <!-- modal de confirmacion -->
    <confirmation-dialog-component
      :show="show_modal_var"
      title="¿Deseas eliminar la solicitud seleccionada?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="(show_modal_var = false), (solicitud_select_var = null)"
      @confirm="DeleteSolicitudFtn"
    />

    <!-- modal de agregar solicitud -->
    <modal-add-soli-component ref="REF_MODAL_CONST" :proceso="proceso" />
  </section>
</template>

<script>
// importacion de componentes locales
import ModalAddSoliComponent from "../ModalAddSoliComponent.vue";

// importacion de componentes globales
import DataTableComponent from "../../../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";

// importacion de librerias
import { mapActions, mapState } from "vuex";

export default {
  name: "TabSolicitudesComponent",

  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
    ModalAddSoliComponent,
  },

  props: {
    proceso: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    // constantes
    HEADERS_CONST: [
      {
        text: "No",
        value: "correlativo",
        align: "center",
      },
      {
        text: "Unidad solicitante",
        value: "detalle_unidad.nombre",
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],

    /*
     * //WARNING
     * Este array es parte de una props para el componente DataTableComponent,
     * por lo que no se debe modificar su estructura, si desea agregar condiciones especiales,
     * favor de crear una funcion del tipo init para cargar las acciones, o simplemente
     * trabajar con slots
     */
    ACTIONS_CONST: [
      {
        tooltip: {
          text: "Editar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-pencil",
        },
        eventName: "editElementEvent",
      },
      {
        tooltip: {
          text: "Eliminar",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-delete",
        },
        eventName: "deleteElementEvent",
      },
    ],

    // variables
    show_modal_var: false,
    solicitud_select_var: null,
  }),

  computed: {
    ...mapState("ProcCompReservaStore", [
      "list_solicitudes_st",
      "pag_solicitudes_st",
    ]),
  },

  methods: {
    ...mapActions("ProcCompReservaStore", ["FetchListSolicAc"]),

    // <-- ----------------- FUNCIONES REGULARES ----------------- -->

    /**
     *
     * @description Funcion para paginar la lista de procesos
     * @param { Number } cantidad_por_pagina
     * @param { Number } pagina
     */
    PaginateFtn({ cantidad_por_pagina, pagina }) {
      this.FetchListSolicAc({
        id_proceso: this.$route.params.id_proceso,
        paginate: { per_page: cantidad_por_pagina, page: pagina },
      });
    },

    /**
     *
     * @description Funcion para redirigir a la vista de editar solicitud
     * @param { Object } item_param
     */
    ToViewFtn(item_param) {
      this.$router.push({
        name: "proceso-compra-reserva-editar-solicitud",
        params: {
          id_proceso: this.$route.params.id_proceso,
          id_solicitud: item_param.id,
        },
      });
    },

    /**
     * @description Funcion para abrir el modal de confirmacion de eliminacion
     * @param { Object } item_param
     */
    OpenModalDeleteFtn(item_param) {
      this.solicitud_select_var = item_param;
      this.show_modal_var = true;
    },

    // ----
    async DeleteSolicitudFtn() {

      const { data, status } =
        await this.services.ProcesoReserva.deleteSolicitudProcReserva(
          this.solicitud_select_var.id
        );

      if (status) {
        this.temporalAlert({
        message: data.message,
        show: true,
        type: 'success',
        });
      }

      this.solicitud_select_var = null;
      this.show_modal_var = false;
      this.FetchListSolicAc({
        id_proceso: this.$route.params.id_proceso,
        paginate: { page: 1, per_page: 10 },
      });

    },
  },

  created() {
    this.FetchListSolicAc({
      id_proceso: this.$route.params.id_proceso,
      paginate: { page: 1, per_page: 10 },
    });
  },
};
</script>
<!-- Componente Tab con informacion de insumos del proceso de compra -->