import { render, staticRenderFns } from "./ModalAddSoliComponent.vue?vue&type=template&id=07c60f85&scoped=true&"
import script from "./ModalAddSoliComponent.vue?vue&type=script&lang=js&"
export * from "./ModalAddSoliComponent.vue?vue&type=script&lang=js&"
import style0 from "./ModalAddSoliComponent.vue?vue&type=style&index=0&id=07c60f85&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c60f85",
  null
  
)

export default component.exports