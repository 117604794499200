<template>
  <section>
    <!-- campo codigo interno -->
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field
          :error-messages="codigoInternoErrorCmp"
          @blur="$v.codigo_interno_var.$touch()"
          @input="$v.codigo_interno_var.$touch()"
          clearable
          label="Código interno *"
          maxlength="150"
          outlined
          v-model="codigo_interno_var"
        />
      </v-col>
    </v-row>

    <!-- campo institucion -->
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-autocomplete
          :error-messages="institucionErrorCmp"
          :items="ctl_institucion_var"
          @blur="$v.institucion_var.$touch()"
          clearable
          item-text="nombre"
          item-value="id"
          label="Institución"
          outlined
          placeholder="Escriba la institución y luego seleccionela"
          v-model="institucion_var"
        />
      </v-col>
    </v-row>

    <!-- campo nombre proceso -->
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-textarea
          :error-messages="nombreProcesoErrorCmp"
          @blur="$v.nombre_proceso_var.$touch()"
          @input="$v.nombre_proceso_var.$touch()"
          clearable
          label="Nombre del proceso *"
          outlined
          v-model="nombre_proceso_var"
        />
      </v-col>
    </v-row>

    <!-- campo monto programado -->
    <v-row>
      <v-col cols="12" sm="6" md="4" class="py-0">
        <money-text-field-component
          ref="REF_MONTO_CONST"
          :error-messages="montoProgramadoErrorCmp"
          @blur="$v.monto_programado_var.$touch()"
          @input="$v.monto_programado_var.$touch()"
          clearable
          label="Monto programado *"
          v-model="monto_programado_var"
          maxlength="15"
        />
      </v-col>
    </v-row>

    <!-- campo modalidad de compra -->
    <v-row>
      <v-col cols="12" md="6" class="py-0">
        <v-select
          :error-messages="modalidadCompraErrorCmp"
          :items="ctl_modalidad_compra_var"
          @blur="$v.modalidad_compra_var.$touch()"
          clearable
          item-text="nombre"
          item-value="id"
          label="Modalidad de compra *"
          outlined
          v-model="modalidad_compra_var"
        />
      </v-col>
    </v-row>

    <!-- campo fecha inicio -->
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <v-menu
          max-width="290px"
          min-width="auto"
          offset-y
          transition="scale-transition"
          v-model="fecha_inicio_var.menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :error-messages="fechaInicioErrorCmp"
              :value="FormatDateFtn(fecha_inicio_var.value)"
              @blur="$v.fecha_inicio_var.$touch()"
              @click:clear="fecha_inicio_var.value = null"
              append-icon="mdi-calendar"
              clearable
              label="Mes/año inicio de proceso *"
              outlined
              placeholder="Seleccione una fecha"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            :max="fecha_contratacion_var.value"
            :min="moment().format('YYYY-MM-DD')"
            no-title
            scrollable
            type="month"
            v-model="fecha_inicio_var.value"
          />
        </v-menu>
      </v-col>

      <!-- campo fecha posible contratacion -->
      <v-col cols="12" sm="6" class="py-0">
        <v-menu
          max-width="290px"
          min-width="auto"
          offset-y
          transition="scale-transition"
          v-model="fecha_contratacion_var.menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :disabled="fecha_inicio_var.value == null"
              :error-messages="fechaContratacionErrorCmp"
              :value="FormatDateFtn(fecha_contratacion_var.value)"
              @blur="$v.fecha_contratacion_var.$touch()"
              @click:clear="fecha_contratacion_var.value = null"
              append-icon="mdi-calendar"
              clearable
              label="Mes/año posible contratación *"
              outlined
              placeholder="Seleccione una fecha"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            :disabled="fecha_inicio_var.value == null"
            :min="fecha_inicio_var.value"
            no-title
            scrollable
            type="month"
            v-model="fecha_contratacion_var.value"
          />
        </v-menu>
      </v-col>
    </v-row>

    <!-- footer -->
    <footer tile class="mt-6 text-center" color="bgMinsal">
      <!-- boton que guarda la informacion general, revisar la funcion para mas info -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        @click="HandlerActionFtn"
      >
        {{ type_create_var ? "Guardar" : "Actualizar" }}
      </v-btn>
    </footer>
  </section>
</template>

<script>
// importacion de componentes globales
import MoneyTextFieldComponent from "../../../../components/MoneyTextFieldComponent.vue";

// importacion de librerias
import { required, helpers } from "vuelidate/lib/validators";
import { mapMutations } from "vuex";

const txtField = helpers.regex("txtField", /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 -]*$/);

export default {
  name: "TabGeneralComponent",

  components: {
    MoneyTextFieldComponent,
  },

  validations: {
    codigo_interno_var: {
      required,
    },
    institucion_var: {
      required,
    },
    nombre_proceso_var: {
      required,
      txtField,
    },
    monto_programado_var: {
      required,
    },
    modalidad_compra_var: {
      required,
    },
    fecha_inicio_var: {
      value: {
        required,
      },
    },
    fecha_contratacion_var: {
      value: {
        required,
      },
    },
  },

  data: () => ({
    // constantes
    SNAPSHOT_CONST: {},

    // variables
    codigo_interno_var: null,
    institucion_var: null,
    nombre_proceso_var: "",
    monto_programado_var: "",
    modalidad_compra_var: null,
    fecha_inicio_var: {
      value: null,
      menu: false,
    },
    fecha_contratacion_var: {
      value: null,
      menu: false,
    },

    ctl_institucion_var: [],
    ctl_modalidad_compra_var: [],

    type_create_var: false,
  }),

  computed: {
    // errores de validacion computados de vuelidate
    codigoInternoErrorCmp() {
      const errors = [];
      if (!this.$v.codigo_interno_var.$dirty) return errors;
      !this.$v.codigo_interno_var.required &&
        errors.push("Código interno es requerido");
      return errors;
    },

    institucionErrorCmp() {
      const errors = [];
      if (!this.$v.institucion_var.$dirty) return errors;
      !this.$v.institucion_var.required &&
        errors.push("Institución es requerida");
      return errors;
    },

    nombreProcesoErrorCmp() {
      const errors = [];
      if (!this.$v.nombre_proceso_var.$dirty) return errors;
      !this.$v.nombre_proceso_var.required &&
        errors.push("Nombre del proceso es requerido");
      !this.$v.nombre_proceso_var.txtField &&
        errors.push(
          "Nombre del proceso no puede contener caracteres especiales"
        );
      return errors;
    },

    montoProgramadoErrorCmp() {
      const errors = [];
      if (!this.$v.monto_programado_var.$dirty) return errors;
      !this.$v.monto_programado_var.required &&
        errors.push("Monto programado es requerido");
      return errors;
    },

    modalidadCompraErrorCmp() {
      const errors = [];
      if (!this.$v.modalidad_compra_var.$dirty) return errors;
      !this.$v.modalidad_compra_var.required &&
        errors.push("Modalidad de compra es requerida");
      return errors;
    },

    fechaInicioErrorCmp() {
      const errors = [];
      if (!this.$v.fecha_inicio_var.value.$dirty) return errors;
      !this.$v.fecha_inicio_var.value.required &&
        errors.push("Fecha de inicio es requerida");
      return errors;
    },

    fechaContratacionErrorCmp() {
      const errors = [];
      if (!this.$v.fecha_contratacion_var.value.$dirty) return errors;
      !this.$v.fecha_contratacion_var.value.required &&
        errors.push("Fecha de posible contratación es requerida");
      return errors;
    },
  },

  methods: {
    ...mapMutations("ProcCompReservaStore", ["SetProcesoCompraMt"]),

    // <-- ----------------- FUNCIONES REGULARES ----------------- -->

    /**
     * @description Funcion que formatea una fecha a formato "MMMM YYYY"
     * @param {Date} date_param - Fecha a formatear
     * @returns {String} Fecha formateada
     */
    FormatDateFtn(date_param) {
      if (date_param != null) {
        return this.moment(date_param).format("MMMM YYYY");
      } else {
        return "";
      }
    },

    /**
     * @description Handler para el boton de guardar o actualizar
     */
    HandlerActionFtn() {
      if (this.type_create_var) {
        this.SaveInfoGeneralFtn();
      } else {
        this.UpdateInfoGeneralFtn();
      }
    },

    // <-- ----------------- FUNCIONES ASINCRONAS ---------------- -->

    /**
     * @description Funcion para obtener los datos de catalogo de instituciones (API)
     * @async
     */
    async FetchCtlInstnFtn() {

      const { data, status } = await this.services.Usuarios.getInstituciones();
      if (status == 200) {
        this.ctl_institucion_var = data;
      }

    },

    /**
     * @description Funcion para obtener los datos de catalogo de modalidades de compra (API)
     * @async
     */
    async FetchCtlModalidadFtn() {

      const { data, status } =
        await this.services.PacProcesos.getProcesoModalidad();
      if (status == 200) {
        this.ctl_modalidad_compra_var = data;
      }

    },

    /**
     *
     * @description Funcion para obtener los datos generales del proceso de compra (API)
     * @param { Number } id_proceso_param
     * @async
     */
    async FetchInfoGeneral(id_proceso_param) {
      if (typeof id_proceso_param !== "number") {
        console.error("El id_proceso_param debe ser un numero");
        return;
      }


      const { data, status } = await this.services.PacProcesos.getProceso(
        id_proceso_param
      );
      if (status == 200) {
        this.codigo_interno_var = data.codigo_interno;
        this.fecha_contratacion_var.value = data.fecha_contratacion;
        this.fecha_inicio_var.value = data.fecha_inicio_proceso;
        this.modalidad_compra_var = data.id_forma_contratacion;
        this.institucion_var = data.id_institucion;
        this.nombre_proceso_var = data.nombre_proceso;
        this.monto_programado_var = data.suma_mercancia;
        this.$refs.REF_MONTO_CONST.updateValue(data.suma_mercancia);

        this.SNAPSHOT_CONST = {
          codigo_interno: data.codigo_interno,
          fecha_fin: data.fecha_contratacion,
          fecha_inicio: data.fecha_inicio_proceso,
          id_forma_contratacion: data.id_forma_contratacion,
          id_institucion: data.id_institucion,
          nombre_proceso: data.nombre_proceso,
          suma_mercancia: data.suma_mercancia,
        };
      }

    },

    /**
     * @description Funcion que guarda los datos generales del proceso de compra en vuex y lanza una petición al backend para guardar los datos (API)
     * @async
     */
    async SaveInfoGeneralFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        const payload_scp_var = {
          codigo_interno: this.codigo_interno_var,
          fecha_fin: this.fecha_contratacion_var.value,
          fecha_inicio: this.fecha_inicio_var.value,
          id_forma_contratacion: this.modalidad_compra_var,
          id_institucion: this.institucion_var,
          id_tipo_proceso: 2,
          nombre: this.nombre_proceso_var,
          suma_mercancia: this.monto_programado_var,
        };


        const { data, status } =
          await this.services.PacProcesos.postProcesoCompra(payload_scp_var);

        if (status == 200) {
          this.temporalAlert({
            message: data.message,
            show: true,
            type: "success",
          });
          this.$router.push({
            name: "proceso-compra-reserva-editar-proceso",
            params: { id_proceso: data.id_proceso_compra },
          });
        }

      }
    },

    /**
     * @description Funcion que actualiza los datos generales del proceso de compra en vuex y lanza una petición al backend para actualizar los datos (API)
     * @async
     */
    async UpdateInfoGeneralFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        const payload_scp_var = {
          codigo_interno: this.codigo_interno_var,
          fecha_fin: this.fecha_contratacion_var.value,
          fecha_inicio: this.fecha_inicio_var.value,
          id_forma_contratacion: this.modalidad_compra_var,
          id_institucion: this.institucion_var,
          nombre_proceso: this.nombre_proceso_var,
          suma_mercancia: this.monto_programado_var,
        };

        let check_similar_scp_var =
          JSON.stringify(this.SNAPSHOT_CONST) ===
          JSON.stringify(payload_scp_var);

        if (check_similar_scp_var) {
          this.temporalAlert({
            message: "No se han realizado cambios",
            show: true,
            type: "info",
          });
          return;
        } else {

          const { data, status } =
            await this.services.PacProcesos.actualizarProceso(
              this.$route.params.id_proceso,
              payload_scp_var
            );

          if (status == 200) {
            this.temporalAlert({
              message: data.message,
              show: true,
              type: "success",
            });

            this.$emit("reload");
          }

        }
      }
    },
  },

  watch: {
    "fecha_inicio_var.value"(val) {
      if (val == null) {
        this.fecha_contratacion_var.value = null;
      }
    },
  },

  created() {
    const ID_PROCESO_CONST = this.$route.params.id_proceso;
    if (ID_PROCESO_CONST != undefined) {
      this.FetchInfoGeneral(Number(ID_PROCESO_CONST));
      this.type_create_var = false;
    } else {
      this.type_create_var = true;
    }
    this.FetchCtlInstnFtn();
    this.FetchCtlModalidadFtn();
  },
};
</script>
<!-- Componente Tab con formulario de datos generales del proceso de compra -->