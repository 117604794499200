<template>
  <section class="mx-0 mx-sm-3 mx-md-6">
    <!-- title -->
    <header
      class="d-flex flex-column flex-sm-row justify-sm-space-between align-center"
    >
      <div class="my-6 text-center text-sm-left">
        <span
          class="text-h5 secondary--text"
          v-text="
            $route.meta.type_action === 'edit'
              ? 'Edición de proceso con reserva'
              : 'Creación de proceso con reserva'
          "
        />
      </div>
      <div>
        <!-- boton que realiza la creación del proceso-->
        <v-btn
          v-if="$route.meta.type_action === 'edit'"
          class="button-extra text-no-style mt-2 mb-8 mt-sm-0 mb-sm-0"
          color="success"
          dark
          @click="CrearProcesoFtn"
        >
          Crear proceso
        </v-btn>
      </div>
    </header>

    <!-- container -->
    <container-p-tabs-component />

    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.push({ name: 'proceso-compra-reserva-list-procesos' })"
      >
        Volver
      </v-btn>
    </footer>
  </section>
</template>

<script>
// importaciones locales
import ContainerPTabsComponent from "./components/ContainerPTabsComponent.vue";

export default {
  name: "mainProcesoView",

  components: {
    ContainerPTabsComponent,
  },

  methods: {
    async CrearProcesoFtn() {

      const { status, data } =
         await this.services.ProcesoReserva.activarProceso(
          this.$route.params.id_proceso,
          1
        );
      if (status == 200) {
        this.temporalAlert({
          message: data.message,
          show: true,
          type: "success",
        });
        this.$router.push({
          name: "proceso-compra-reserva-list-procesos",
        });
      }


    },
  },

  beforeDestroy() {
    this.$store.commit("ProcCompReservaStore/ResetStateMt");
  },
};
</script>
<!-- Vista de creación/edición de proceso con reserva -->
